import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import moment from "moment-timezone";
import EventModule from "@/store/module/shared/EventModule";
import AdminModule from "@/store/module/admin/AdminModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import EventCard from "./EventCard.vue";
let EventsTable = class EventsTable extends Vue {
    constructor() {
        super(...arguments);
        this.selected = [];
        this.search = "";
        this.loading = true;
        this.page = 1;
        this.itemsPerPage = 25;
        this.pagesCount = 0;
        this.headers = [
            {
                text: "Название",
                sortable: true,
                value: "name",
            },
            {
                text: "Тип",
                sortable: true,
                value: "type",
            },
            {
                text: "Начало",
                sortable: true,
                value: "startDate",
            },
            {
                text: "Конец",
                sortable: true,
                value: "endDate",
            },
            {
                text: "Конец регистрации",
                sortable: true,
                value: "registrationEndDate",
            },
            {
                text: "Регистрация открыта",
                sortable: true,
                value: "isOpenForRegistration",
            },
            {
                text: "Наличие взноса",
                sortable: true,
                value: "participationFee",
            },
            { text: "", value: "data-table-expand" },
        ];
    }
    onSelect() {
        this.$emit("on-select", this.selected);
    }
    onClearSelectedChange() {
        this.selected = [];
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get eventsRegionalStatuses() {
        return this.$store.getters[HandbookModule.types.getters.EVENT_REGIONAL_STATUSES];
    }
    get allEvents() {
        return this.$store.getters[EventModule.types.getters.EVENTS];
    }
    get events() {
        if (this.category === this.eventsRegionalStatuses.length + 1) {
            return this.allEvents.filter((item) => item.isPremoderated);
        }
        return this.allEvents.filter((item) => item.type.regionalStatusId === this.category && item.isPremoderated === false);
    }
    // @Watch("events")
    // onEventsUpdate() {
    //   if (this.events.length === 0) {
    //     this.loading = true
    //   } else {
    //     this.loading = false
    //   }
    // }
    formatDate(date) {
        return moment(date).tz("Europe/Moscow").format("YYYY-MM-DD");
    }
    async mounted() {
        await this.$store.dispatch(AdminModule.types.actions.WAIT_FOR_ADMIN_LOADING);
        const regionQuery = {};
        if (this.admin !== null && this.admin.regionId && !this.admin.isSuperAuth) {
            regionQuery.regionId = this.admin.regionId;
        }
        await this.$store.dispatch(EventModule.types.actions.GET_EVENTS, regionQuery);
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number, default: 1 })
], EventsTable.prototype, "category", void 0);
__decorate([
    Watch("selected")
], EventsTable.prototype, "onSelect", null);
__decorate([
    Watch("clearSelected")
], EventsTable.prototype, "onClearSelectedChange", null);
EventsTable = __decorate([
    Component({
        components: {
            EventCard,
        },
    })
], EventsTable);
export default EventsTable;

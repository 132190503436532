import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import moment from "moment-timezone";
import AdminModule from "@/store/module/admin/AdminModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import { routePath } from "@/router/router-constants";
import EventDialog from "@/components/shared/dialogs/Event.vue";
let EventCard = class EventCard extends Vue {
    constructor() {
        super(...arguments);
        this.updateEvent = {
            dialog: false,
        };
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isAdmin() {
        return !!this.admin;
    }
    get canEdit() {
        if (!this.isAdmin)
            return false;
        if (this.isSuperAdmin)
            return true;
        if (this.event.creatorId !== null && this.event.creator.id === this.admin.id)
            return true;
        if (this.event && this.isAdmin && this.admin && this.event?.address?.regionId === this.admin.regionId)
            return true;
        return false;
    }
    get categoriesNames() {
        return this.event.categories.map((item) => `${item.category.name} от ${item.category.ageCategory.ageFrom} до ${item.category.ageCategory.ageTo} лет`);
    }
    get disciplinesTree() {
        const tree = [];
        this.event.disciplines.map((item) => {
            if (item.disciplineId === null) {
                tree.push({
                    ...item.disciplineGroup,
                    children: [],
                });
            }
            else {
                let parent = tree.find((fItem) => fItem.id === item.disciplineGroupId);
                if (parent === undefined) {
                    parent = {
                        ...this.$store.getters[HandbookModule.types.getters.EVENT_DISCIPLINES_GROUPS].find((fItem) => fItem.id === item.disciplineGroupId),
                        children: [],
                    };
                    tree.push(parent);
                }
                parent.children.push(item.discipline);
            }
        });
        return tree;
    }
    get eventAvatar() {
        return `${IMAGE_SERVER_URL}/public/${this.event.logo}`;
    }
    get profile() {
        return {
            main: [
                { title: "Название", value: this.event.name },
                { title: "Тип", value: this.event.type.name },
                {
                    title: "Начало",
                    value: moment(this.event.startDate).tz("Europe/Moscow").format("YYYY-MM-DD"),
                },
                {
                    title: "Конец",
                    value: moment(this.event.endDate).tz("Europe/Moscow").format("YYYY-MM-DD"),
                },
                {
                    title: "Конец регистрации",
                    value: moment(this.event.registrationEndDate).tz("Europe/Moscow").format("YYYY-MM-DD"),
                },
                { title: "Регистрация открыта", value: this.event.isOpenForRegistration === true ? "Да" : "Нет" },
                { title: "Наличие взноса", value: this.event.participationFee === null ? "Нет" : "Есть" },
            ],
            additional: [
                { title: "Категории", value: "<br><br>" + this.categoriesNames.join("<br>"), isTree: false },
                { title: "Дисциплины", value: this.disciplinesTree, isTree: true },
            ],
        };
    }
    goToEventPage() {
        this.$router.push(routePath.admin.EVENT_FACTORY(this.event.id));
    }
    mounted() { }
};
__decorate([
    Prop({ type: Object })
], EventCard.prototype, "event", void 0);
EventCard = __decorate([
    Component({
        components: {
            EventDialog,
        },
    })
], EventCard);
export default EventCard;

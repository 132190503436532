import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import EventModule from "@/store/module/shared/EventModule";
import CategoriesTabs from "@/components/admin/events/CategoriesTabs.vue";
import HeaderActions from "@/components/admin/events/HeaderActions.vue";
import EventsTable from "@/components/admin/events/EventsTable.vue";
import AdminModule from "@/store/module/admin/AdminModule";
let Events = class Events extends Vue {
    constructor() {
        super(...arguments);
        this.category = 1;
        this.selectedEvents = [];
        this.clearSelected = false;
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get isAdmin() {
        return !!this.admin;
    }
    get allEvents() {
        return this.$store.getters[EventModule.types.getters.EVENTS];
    }
    setSelectedEvents(events) {
        this.selectedEvents = events;
    }
    setCategory(category) {
        this.category = category;
    }
    async removeEvents() {
        for (let event of this.selectedEvents) {
            await this.$store.dispatch(EventModule.types.actions.DELETE_EVENT, { id: event.id });
        }
        this.selectedEvents.splice(0);
        this.clearSelected = !this.clearSelected;
        this.$notify({
            type: "success",
            title: "Выбранные соревнования успешно удалены",
        });
    }
    async depremoderate() {
        for (let event of this.selectedEvents) {
            await this.$store.dispatch(EventModule.types.actions.UPDATE_EVENT, {
                eventId: event.id,
                event: { isPremoderated: false },
            });
        }
        this.selectedEvents.splice(0);
        this.clearSelected = !this.clearSelected;
        this.$notify({
            type: "success",
            title: "Выбранные соревнования успешно опубликованны",
        });
    }
};
Events = __decorate([
    Component({
        components: {
            CategoriesTabs,
            HeaderActions,
            EventsTable,
        },
    })
], Events);
export default Events;

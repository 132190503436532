import { __decorate } from "tslib";
import AdminModule from "@/store/module/admin/AdminModule";
import EventModule from "@/store/module/shared/EventModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
let CategoriesTabs = class CategoriesTabs extends Vue {
    constructor() {
        super(...arguments);
        this.activeTab = 0;
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get eventCategories() {
        return this.$store.getters[HandbookModule.types.getters.EVENT_REGIONAL_STATUSES];
    }
    get events() {
        return this.$store.getters[EventModule.types.getters.EVENTS];
    }
    getEventsCountByRegionalStatus(regionanlStatusId) {
        return this.events.filter((item) => item.type.regionalStatusId === regionanlStatusId && item.isPremoderated === false).length;
    }
    getPremoderatedEventsCount() {
        return this.events.filter((e) => e.isPremoderated).length;
    }
    changeCategory(id) {
        return id;
    }
};
__decorate([
    Emit()
], CategoriesTabs.prototype, "changeCategory", null);
CategoriesTabs = __decorate([
    Component
], CategoriesTabs);
export default CategoriesTabs;
